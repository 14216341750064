import axios, { type AxiosRequestConfig, type AxiosError } from 'axios';
import { merge } from 'lodash/fp';

import { requestInterceptor } from './interceptors';

export const api = axios.create();

api.defaults.baseURL = process.env.NEXT_PUBLIC_ENDPOINT_LEROY;

api.interceptors.request.use(async (config) => {
  const headers: Record<string, string> = {};

  for (const header in config.headers) {
    if (
      ['string', 'number', 'boolean'].includes(typeof config.headers[header])
    ) {
      headers[header] = String(config.headers[header]);
    }
  }

  const result = await requestInterceptor({ ...config, headers });

  return { ...config, ...result };
});

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = axios.CancelToken.source();

  const promise = api({
    ...merge(config, options),
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default api;

/**
 * In some case with react-query and swr you want to be able
 * to overridethe return error type so you can also do it here like this
 */
export type ErrorType<Error> = AxiosError<Error>;
