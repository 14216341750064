import { Suspense } from 'react';

import { Shell } from './components';
import { DownloadApp } from './container';

function PromotionalBox() {
  return (
    <Suspense fallback={<Shell />}>
      <DownloadApp />
    </Suspense>
  );
}

export default PromotionalBox;
