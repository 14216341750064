import { Button, Text } from '@backyard-ui/core';

import { Icon } from '../Icon';

interface PromotionalBoxData {
  isEnable: boolean;
  title: string;
  description: string;
  link: string;
  callToAction: string;
}

function Banner(props: PromotionalBoxData) {
  const { isEnable, title, description, link, callToAction } = props;

  if (!isEnable) {
    return;
  }

  return (
    <div className="box-border flex items-center bg-green-200 p-4 text-black md:hidden">
      <div className="max-w-10">
        <Icon />
      </div>
      <div className="grow px-2 leading-5">
        <Text size="md">{title}</Text>
        <Text size="md" weight="bold">
          {description}
        </Text>
      </div>
      <div>
        <Button asChild>
          <a
            href={link}
            className="h-8 rounded-s-lg px-4 py-2 text-md leading-4"
          >
            {callToAction}
          </a>
        </Button>
      </div>
    </div>
  );
}

export default Banner;
