import { customInstance } from '@/infra/http/axios';

interface ApiResponse {
  data: {
    enableBannerInstallApp: boolean;
    textFirstLineBanner: string;
    textSecondLineBanner: string;
    textButtonBanner: string;
    linkButtonBanner: string;
  };
}

interface BannerInstallApp {
  isEnable: boolean;
  title: string;
  description: string;
  callToAction: string;
  link: string;
}

export const getAppInstallBannerData = async (): Promise<BannerInstallApp> => {
  try {
    const { data } = await customInstance<ApiResponse>({
      url: `/api/v3/app/banner-install-app`,
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    });

    return {
      isEnable: data.enableBannerInstallApp,
      title: data.textFirstLineBanner,
      description: data.textSecondLineBanner,
      callToAction: data.textButtonBanner,
      link: data.linkButtonBanner,
    };
  } catch (e) {
    return {
      isEnable: false,
      title: 'Vem para o app',
      description: 'Descontos exclusivos',
      callToAction: 'Ir para o app',
      link: 'https://www.leroymerlin.com.br/baixar-app',
    };
  }
};
