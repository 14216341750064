import { getAppInstallBannerData } from '@/infra/services/app-install-banner';

import { Banner } from '../../components/Banner';

async function DownloadApp() {
  const promotionalBoxData = await getAppInstallBannerData();
  return <Banner {...promotionalBoxData} />;
}

export default DownloadApp;
